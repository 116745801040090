export function capitalize(string) {
  if (!string) return;
  if (typeof string !== "string") return string;

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeWords(string) {
  return string
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");
}
