import { id } from "date-fns/locale";
import { capitalize, capitalizeWords } from "./lib/capitalize";

export const CHAT_URL = "https://discord.gg/7xEEqhWHWp" as const;
export const OG_SITE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
export const SITE_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const CHECKOUT_LINK = "/checkout" as const;
export const PASSWORD_RESET_LINK = "/reset-password" as const;
export const PASSWORD_SET_LINK = "/set-password" as const;
export const SIGN_IN_LINK = "/login" as const;

export const ABOUT_LINK = "/about" as const;
export const ACCOUNT_LINK = "/account" as const;
export const ADVERTISE_LINK = "/advertise-on-kietzee" as const;
export const BECOME_EXPERT_LINK = "/kietzee-guides-program" as const;
export const BLOG_LINK = "/blog" as const;
export const CONTACT_LINK = "/contact" as const;
export const EVENTS_LINK = "/events" as const;
export const GUIDES_LINK = "/guides";
export const HOME_LINK = "/" as const;
export const KITAS_LINK = "/kitas" as const;
export const KITA_REVIEWS_LINK_PARTIAL = "/reviews/new" as const;
export const KITA_REVIEW_SUBMITTED_LINK = "/kitas/review-submitted" as const;
export const IMPRINT_LINK = "/imprint" as const;
export const NEIGHBORHOOD_LINK = "/map" as const;
export const PERKS_LINK = "/perks";
export const PLACE_LINK = "/place" as const;
export const PRIVATE_LINK = "/privacy_policy" as const;
export const TERMS_LINK = "/terms_conditions" as const;
export const TIMELINE_LINK = "/timeline" as const;

export const SUPPORT_EMAIL = "lisa@kietzee.com" as const;
export const KTZ_EMAIL = "hi@kietzee.com" as const;

export const POST_SIGNUP_REDIRECT_SESSION_STORAGE = "ktz__postSignupRedirect";

export const RAILS_SESSION = "_kietzee_session_global";

export const VISITOR_TIER = "visitor";
export const FREE_TIER = "free";
export const PREMIUM_TIER = "premium";

export const KITA_SEARCH = "kita_search" as const;
export const EXPECTING = "expecting" as const;

export const screens = {
  xs: "500px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
} as const;

export const SELECT_KITA_MAP_FILTERS = [
  "themes",
  "languages",
  "approaches",
] as const;

export const LANGUAGE_MAP = {
  ar: "Arabic",
  cs: "Czech",
  da: "Danish",
  de: "German",
  el: "Greek",
  en: "English",
  es: "Spanish",
  fr: "French",
  he: "Hebrew",
  it: "Italian",
  ko: "Korean",
  ku: "Kurdish",
  nl: "Dutch",
  no: "Norwegian",
  pl: "Polish",
  pt: "Portuguese",
  ru: "Russian",
  sl: "Slovenian",
  sr: "Serbian",
  sv: "Swedish",
  tr: "Turkish",
  vi: "Vietnamese",
  zh: "Chinese",
};

export const ACUPUNCTURE_TAG = "mi3wxlqqlTA5A2Q6awhTz";
export const ADULT_THERAPY_TAG = "4xrk7mJFwT42rEEqd9uryN";
export const ALLERGY_NUTRITION_TAG = "1EwkOIRVpKj6vn4NTuf0Kf";
export const BABY_NUTRITION_TAG = "3HZ1ys8MjWzqdA45iFQWGC";
export const BABY_PHOTOGRAPHY_TAG = "1HGIXHbT91XgGEwufBhMRq";
export const BABY_PRODUCTS_TAG = "5OSBOD2U62OgjynzHUp9kU";
export const BABY_SLEEP_TAG = "fpjhbQvQbwOqER6s00lwn";
export const BENEFITS_TAG = "2YDCxLCb0gto98cDWnJHmH";
export const BIRTH_DOULA_TAG = "6ptrYiToEUL9nMIOPFgoVQ";
export const BIRTH_PLAN_TAG = "1xwA4OkdyftUqYHaH8J2J2";
export const BIRTH_PREPARATION_TAG = "6614IGpk2JdHJvcyyzolys";
export const BIRTH_TAG = "6BCAxxzCH44sH8Er3VWDe4";
export const BOTTLE_FEEDING_TAG = "67p5TA2F9vNMRq7krhEsil";
export const BREASTFEEDING_TAG = "4OOs6TOS8NcrY8m6WevDSl";
export const C_SECTION_TAG = "4CSNRabugDeo0WFktJjELx";
export const CAREER_COACHING_TAG = "7FI7mMOPhywMCOJifQ9S1G";
export const CHILD_DEVELOPMENT_TAG = "1aytnzBLFGeWdjEXOoH4h1";
export const CHILD_NUTRITION_TAG = "NlrJS8HjlZON7ezH0RCvY";
export const CHILDBIRTH_EDUCATION_TAG = "3oPlyLriWlcRM8xPwADL4E";
export const CHILDCARE_TAG = "5xl6HF4ZFR5wOtyrfW2v4E";
export const CHILDRENS_THERAPY_TAG = "2LvtPk0qrbjnEu766soX0b";
export const CHIROPRACTOR_TAG = "6u5oV1MvbIBPIegKCGTp2Y";
export const CLOTH_DIAPERS_TAG = "4a6REd5ykgDLmAB0rrIofu";
export const CLOTHES_TAG = "7hH3fdPUrVpeodOW13tgrl";
export const COLIC_TAG = "FLO7c1Vi6gF3CSS6nbvL3";
export const COUPLES_THERAPY_TAG = "65nINROhFdzJ8ELaUvFQjw";
export const DAYCARE_TAG = "IIbeS4VMQ65MFP0QGvw5P";
export const DOULAS_TAG = "4KJWO5fbj1h6HA4WfkrrO9";
export const EINGEWOEHNUNG_TAG = "7LXIIkZ6aX0tItLIMJgW6x";
export const ELTERNGELD_TAG = "6Q40mdGB1knE0dbcXHOmM3";
export const ELTERNZEIT_TAG = "NAu09eRXrQ70hsnm3z6Vf";
export const EMPLOYMENT_LAW_TAG = "23rPja8Hs52GUDNM5furKz";
export const EXPECTING_TAG = "5SuctotOBXQB8x1qsKfADd";
export const FAMILY_COACHING_TAG = "0uNBtHIXxWJYqKsGvHVam";
export const FAMILY_PHOTOGRAPHY_TAG = "Q6QJ06Yolm7FIJGzDnpjq";
export const FAMILY_SLEEP_TAG = "2bY7K4cxg81I0Zrvz1GwE4";
export const FAMILY_THERAPY_TAG = "5YBT8vszxcBtIHoNX2uylG";
export const FERTILITY_TAG = "579PzFkdHxBFrgbywK55u8";
export const FINANCES_TAG = "7LChvGzS6v18laJwf9V9C1";
export const FIRST_AID_TAG = "2mt9gD8SWKe0St27uv5KdT";
export const FULL_SPECTRUM_DOULA_TAG = "2qMClpUMetyBC1L6YPOU26";
export const HEALTH_COACH_TAG = "1iXxSOp6H5zajt2J7kVgI9";
export const HEALTH_INSURANCE_TAG = "4gkFq8h1zsVo2Jdj73VZ5i";
export const HOME_VISIT_TAG = "5sJsP9No0saOISftNL0EaN";
export const HYPNOBIRTHING_TAG = "6wt86dTBWsd3xp3hMSO1P3";
export const IMMIGRATION_LAW_TAG = "4N0GpJZbvugsTsHV1gaWA9";
export const INFANT_TAG = "1KUiZazo7bR1J2RCXQnOWh";
export const INFANT_LOSS_TAG = "2u9UKitGDlW4N56k4mZ1gb";
export const INSURANCE_TAG = "67l2KSy4Gi0n8Itnbl5apT";
export const KINDERGELD_TAG = "7uSKIP5EULt8StliClVoww";
export const KITA_GUTSCHEIN_TAG = "5WSfzo6AEAHtbWAFAG9Rw3";
export const KITA_TAG = "1hBvPPR5tdHpscJwpwAaHX";
export const LACTATION_CONSULTANT_TAG = "7dVDlItj2rksI69iDM6uKQ";
export const LANGUAGE_COACHING_TAG = "1nUMy7tMKXlZNFQpYPRZHE";
export const LEARNING_THERAPY_TAG = "4hOpTiKmnHHKucrGNjSB3Z";
export const LEISURE_TAG = "4y7Apo52YOLy7A3GpEZSHz";
export const LEGAL_TAG = "6aJbpBFjUUMcS2qd95pf6M";
export const LIFESTYLE_TAG = "37NWwzpLJUKqM9dgiyrgSw";
export const MASSAGE_TAG = "hAXFfQpTaNRRdlpDwcdvq";
export const MATERNAL_HEALTH_TAG = "5sONCgZaigcJVLTvlfmO0P";
export const MATERNAL_NUTRITION_TAG = "2qJUzWb2pfBLlsZejY8yWX";
export const MEDITATION_TAG = "8VAT5Rhe4I2A7q7SsGxZA";
export const MINDFULNESS_TAG = "3YncqnHmbOSkwuFsal99rw";
export const MIDWIVES_TAG = "1YabWWl2CD2MRsMp4Ez4cc";
export const MORNING_SICKNESS_TAG = "5QqPeMojCJT8exkk9wUMCR";
export const MUETTERPFLEGERIN_TAG = "3thmG6PfwNrsFfITAErPMd";
export const MULTILINGUAL_TAG = "4V0Te2NNYwF4jZouj8Z4WY";
export const NANNY_TAG = "1vLhgKtHX1OYaXsoak1YRR";
export const NEURODIVERGENT_PARENTS_TAG = "5G3MQziIA97x9tmNObFCoN";
export const OBGYN_TAG = "3mje8waQ5ydoJGCyp5ZkT4";
export const OFFICE_TAG = "5uNrtlgr4rTS3Lq7KVIUck";
export const ONLINE_TAG = "2lJvUN6yTMKwxYHUla23Sl";
export const OSTEOPATH_TAG = "8riy5mjAyEAIWpJfZhfho";
export const OVERNIGHT_SUPPORT_TAG = "asSKPsCMmfbc3SLxotcXj";
export const PARTY_ENTERTAINER_TAG = "5e9HiFa5Yfx5h9mdolSRhb";
export const PAPERWORK_TAG = "9JOhR6W4nKDJSTQ7QqVaU";
export const PEDIATRICIAN_TAG = "75a58k6OzJ5KR6DLjievnq";
export const PLACENTA_SERVICES_TAG = "5nXC7pzS8tCVeMZWGHsaVJ";
export const POSTNATAL_CEREMONIES_TAG = "2lMoQ6MKzEtsUYJCu4fMq2";
export const POSTNATAL_FITNESS_TAG = "74HZg8924P8uxTKnCSmet1";
export const POSTPARTUM_RECOVERY_TAG = "3eTWFBWpRommPXtVhB2d58";
export const POSTPARTUM_DEPRESSION_TAG = "1MXwnHtZ9t0nQbep74oN6G";
export const POSTPARTUM_DOULA_TAG = "5QfnYQmIQnHGMKwvw9VXUM";
export const POSTPARTUM_FOOD_TAG = "5HX0mH6ahSaAIvponN46gT";
export const POSTPARTUM_TAG = "fbqcNLogpuSzzdUs0BTDN";
export const PREGNANCY_APPOINTMENTS_TAG = "3BA7nNTiBMZx7MP40TAYn0";
export const PREGNANCY_DIABETES_TAG = "66dKRu4X67mnKjReJxpTUP";
export const PREGNANCY_LOSS_TAG = "446aliAIZ8d2ZQM5kACfFv";
export const PREGNANCY_ORGANIZATION_TAG = "6a9OflUQ2gAgAvj6Dfr9d2";
export const PREGNANCY_PHOTOGRAPHY_TAG = "1c7duFlWK9khqpohgT6M1S";
export const PRENATAL_CEREMONIES_TAG = "522xyC1tESEWp1qJD3Gx9G";
export const PRENATAL_DOULA_TAG = "5hY5o3wnnmqOSQLOLFZUTc";
export const PRENATAL_FITNESS_TAG = "QzHgLA1neP8csdlAvEU73";
export const PRENATAL_SINGING_TAG = "3oGHbv4YMC6A83bgG4DNjf";
export const PRESCHOOLER_TAG = "3gVPc1qWZLNY3aDz9j4UoW";
export const RELOCATION_TAG = "2hesDeXHv0NRxlOgrQHBA8";
export const SINGLE_PARENTS_TAG = "6MGJagT10Ov03Ym8Xr3m8D";
export const SUMMER_TAG = "3rXlhrp3XNezbCbIdwKuUI";
export const TEETHING_TAG = "6CUsONYJQUFWsbgArogBZB";
export const TODDLER_TAG = "uNldaHD3ITPvQ8dHzbdMG";
export const TRAVEL_TAG = "4249Y1z5xAJhiqMqC5Sm2e";
export const TRAUMATIC_BIRTH_TAG = "3Vuv92uBkUXeDFQ9mz62L8";
export const WOCHENBETT_TAG = "6QrsuW3fxMtKOzWWMavsZP";
export const WORKING_PARENT_TAG = "6q05qmm0yn2iLIVHeH3vRF";
export const YOGA_TAG = "5lkLvlKyzBh9WrAInVFSMJ";

export const ALL_OTHER_CITIES_TAG = "YvfOA3FGEmSyWCj79JVYE";
export const BERLIN_ALL_DISTRICTS_TAG = "1bsgyleosCjBgx4ocgEEqy";
export const BERLIN_CHARLOTTENBURG_TAG = "5sbIeeo4N5AjBALfZhrQhr";
export const BERLIN_FRIEDRICHSHAIN_TAG = "5L5a9bDsgPLBLYuTWbe2Ei";
export const BERLIN_HELLERSDORF_TAG = "1M13qiZyGZ30ruJ3pgZoIB";
export const BERLIN_KOEPENICK_TAG = "2b5cytZl9eTx42XYkbp0y0";
export const BERLIN_KREUZBERG_TAG = "5L5a9bDsgPLBLYuTWbe2Ei";
export const BERLIN_LICHTENBERG_TAG = "4vV0ttYs8IVy0CUzLtukXr";
export const BERLIN_MARZAHN_TAG = "1M13qiZyGZ30ruJ3pgZoIB";
export const BERLIN_MITTE_TAG = "28KlROFgqcC7QlhlRZIKjI";
export const BERLIN_NEUKOELLN_TAG = "3bKgGM25tqP0Q2mR8xglSP";
export const BERLIN_PRENZLAUER_BERG_TAG = "7owB5PH7BDw7bwLjPr6Gvc";
export const BERLIN_PANKOW_TAG = "7owB5PH7BDw7bwLjPr6Gvc";
export const BERLIN_REINICKENDORF_TAG = "Mm7dX19oaxvywTDDStUrG";
export const BERLIN_SCHOENEBERG_TAG = "4tdxq7OLC2VDitB072gvkp";
export const BERLIN_SPANDAU_TAG = "5Ord9aalsNFJqQqYEDWVN1";
export const BERLIN_STEGLITZ_TAG = "75UQIANxwjwrJ5aQG5AC2q";
export const BERLIN_TEMPELHOF_TAG = "4tdxq7OLC2VDitB072gvkp";
export const BERLIN_TREPTOW_TAG = "2b5cytZl9eTx42XYkbp0y0";
export const BERLIN_WILMERSDORF_TAG = "5sbIeeo4N5AjBALfZhrQhr";
export const BERLIN_ZEHLENDORF_TAG = "75UQIANxwjwrJ5aQG5AC2q";
export const COLOGNE_TAG = "4VIB0FWUZAllY90LbfBjDS";
export const DUESSELDORF_TAG = "ws5KKYcQImx4Jr2ix5Nwg";
export const FRANKFURT_TAG = "x9fBphhXVrvrcb0bqUyEJ";
export const GERMANY_TAG = "2s6JARWejqWwO1Q6Jh2qz9";

export const ONLINE = "online";
export const BERLIN = "berlin";
export const BERLIN_ALL_DISTRICTS = "berlin-all-districts";
export const COLOGNE = "cologne";
export const DUESSELDORF = "duesseldorf";
export const FRANKFURT = "frankfurt";
export const GERMANY = "germany";
export const ALL_OTHER_CITIES = "all_other_cities";

export const CHARLOTTENBURG = "charlottenburg";
export const FRIEDRICHSHAIN = "friedrichshain";
export const HELLERSDORF = "hellersdorf";
export const KOEPENICK = "köpenick";
export const KREUZBERG = "kreuzberg";
export const LICHTENBERG = "lichtenberg";
export const MARZAHN = "marzahn";
export const MITTE = "mitte";
export const NEUKOELLN = "neukölln";
export const PRENZLAUER_BERG = "prenzlauer berg";
export const PANKOW = "pankow";
export const REINICKENDORF = "reinickendorf";
export const SCHOENEBERG = "schöneberg";
export const SPANDAU = "spandau";
export const STEGLITZ = "steglitz";
export const TEMPELHOF = "tempelhof";
export const TREPTOW = "treptow";
export const WILMERSDORF = "wilmersdorf";
export const ZEHLENDORF = "zehlendorf";

export const validMediums = [
  "Another site",
  "Facebook",
  "Google",
  "Instagram",
  "Someone you know",
  "WhatsApp Group",
  "Other",
];

export const validDistricts = [
  {
    key: CHARLOTTENBURG,
    name: capitalize(CHARLOTTENBURG),
    lat: 52.5168,
    lon: 13.3041,
  },
  {
    key: FRIEDRICHSHAIN,
    lat: 52.5155,
    lon: 13.4546,
    name: capitalize(FRIEDRICHSHAIN),
  },
  {
    key: HELLERSDORF,
    lat: 52.537,
    lon: 13.6074,
    name: capitalize(HELLERSDORF),
  },
  { key: KOEPENICK, lat: 52.4423, lon: 13.5823, name: capitalize(KOEPENICK) },
  { key: KREUZBERG, lat: 52.4993, lon: 13.4034, name: capitalize(KREUZBERG) },
  {
    key: LICHTENBERG,
    lat: 52.515,
    lon: 13.4997,
    name: capitalize(LICHTENBERG),
  },
  { key: MARZAHN, lat: 52.5425, lon: 13.5654, name: capitalize(MARZAHN) },
  { key: MITTE, lat: 52.52, lon: 13.405, name: capitalize(MITTE) },
  { key: NEUKOELLN, lat: 52.4825, lon: 13.4237, name: capitalize(NEUKOELLN) },
  {
    key: PRENZLAUER_BERG,
    lat: 52.5388,
    lon: 13.4241,
    name: capitalize(PRENZLAUER_BERG),
  },
  { key: PANKOW, lat: 52.567, lon: 13.402, name: capitalize(PANKOW) },
  {
    key: REINICKENDORF,
    lat: 52.5708,
    lon: 13.3368,
    name: capitalize(REINICKENDORF),
  },
  {
    key: SCHOENEBERG,
    lat: 52.4862,
    lon: 13.3443,
    name: capitalize(SCHOENEBERG),
  },
  { key: SPANDAU, lat: 52.536, lon: 13.199, name: capitalize(SPANDAU) },
  { key: STEGLITZ, lat: 52.4565, lon: 13.3326, name: capitalize(STEGLITZ) },
  { key: TEMPELHOF, lat: 52.4758, lon: 13.4023, name: capitalize(TEMPELHOF) },
  { key: TREPTOW, lat: 52.4565, lon: 13.4904, name: capitalize(TREPTOW) },
  { key: WILMERSDORF, lat: 52.486, lon: 13.304, name: capitalize(WILMERSDORF) },
  { key: ZEHLENDORF, lat: 52.433, lon: 13.259, name: capitalize(ZEHLENDORF) },
  { key: COLOGNE, lat: 50.9375, lon: 6.9603, name: capitalize(COLOGNE) },
  {
    key: DUESSELDORF,
    lat: 51.2277,
    lon: 6.7735,
    name: capitalize(DUESSELDORF),
  },
  { key: FRANKFURT, lat: 50.1109, lon: 8.6821, name: capitalize(FRANKFURT) },
  {
    key: ALL_OTHER_CITIES,
    lat: 51.1657,
    lon: 10.4515,
    name: capitalize(ALL_OTHER_CITIES),
  },
];

export const validBerlinDistricts = [
  {
    key: CHARLOTTENBURG,
    name: capitalize(CHARLOTTENBURG),
    lat: 52.5168,
    lon: 13.3041,
    id: BERLIN_CHARLOTTENBURG_TAG,
  },
  {
    key: FRIEDRICHSHAIN,
    lat: 52.5155,
    lon: 13.4546,
    name: capitalize(FRIEDRICHSHAIN),
    id: BERLIN_FRIEDRICHSHAIN_TAG,
  },
  {
    key: HELLERSDORF,
    lat: 52.537,
    lon: 13.6074,
    name: capitalize(HELLERSDORF),
    id: BERLIN_HELLERSDORF_TAG,
  },
  {
    key: KOEPENICK,
    lat: 52.4423,
    lon: 13.5823,
    name: capitalize(KOEPENICK),
    id: BERLIN_KOEPENICK_TAG,
  },
  {
    key: KREUZBERG,
    lat: 52.4993,
    lon: 13.4034,
    name: capitalize(KREUZBERG),
    id: BERLIN_KREUZBERG_TAG,
  },
  {
    key: LICHTENBERG,
    lat: 52.515,
    lon: 13.4997,
    name: capitalize(LICHTENBERG),
    id: BERLIN_LICHTENBERG_TAG,
  },
  {
    key: MARZAHN,
    lat: 52.5425,
    lon: 13.5654,
    name: capitalize(MARZAHN),
    id: BERLIN_MARZAHN_TAG,
  },
  {
    key: MITTE,
    lat: 52.52,
    lon: 13.405,
    name: capitalize(MITTE),
    id: BERLIN_MITTE_TAG,
  },
  {
    key: NEUKOELLN,
    lat: 52.4825,
    lon: 13.4237,
    name: capitalize(NEUKOELLN),
    id: BERLIN_NEUKOELLN_TAG,
  },
  {
    key: PRENZLAUER_BERG,
    lat: 52.5388,
    lon: 13.4241,
    name: capitalizeWords(PRENZLAUER_BERG),
    id: BERLIN_PRENZLAUER_BERG_TAG,
  },
  {
    key: PANKOW,
    lat: 52.567,
    lon: 13.402,
    name: capitalize(PANKOW),
    id: BERLIN_PANKOW_TAG,
  },
  {
    key: REINICKENDORF,
    lat: 52.5708,
    lon: 13.3368,
    name: capitalize(REINICKENDORF),
    id: BERLIN_REINICKENDORF_TAG,
  },
  {
    key: SCHOENEBERG,
    lat: 52.4862,
    lon: 13.3443,
    name: capitalize(SCHOENEBERG),
    id: BERLIN_SCHOENEBERG_TAG,
  },
  {
    key: SPANDAU,
    lat: 52.536,
    lon: 13.199,
    name: capitalize(SPANDAU),
    id: BERLIN_SPANDAU_TAG,
  },
  {
    key: STEGLITZ,
    lat: 52.4565,
    lon: 13.3326,
    name: capitalize(STEGLITZ),
    id: BERLIN_STEGLITZ_TAG,
  },
  {
    key: TEMPELHOF,
    lat: 52.4758,
    lon: 13.4023,
    name: capitalize(TEMPELHOF),
    id: BERLIN_TEMPELHOF_TAG,
  },
  {
    key: TREPTOW,
    lat: 52.4565,
    lon: 13.4904,
    name: capitalize(TREPTOW),
    id: BERLIN_TREPTOW_TAG,
  },
  {
    key: WILMERSDORF,
    lat: 52.486,
    lon: 13.304,
    name: capitalize(WILMERSDORF),
    id: BERLIN_WILMERSDORF_TAG,
  },
  {
    key: ZEHLENDORF,
    lat: 52.433,
    lon: 13.259,
    name: capitalize(ZEHLENDORF),
    id: BERLIN_ZEHLENDORF_TAG,
  },
];

export const validBerlinDistrictsMap = validBerlinDistricts.reduce(
  (acc, district) => {
    acc[district.key] = district;
    return acc;
  },
  {} as Record<string, (typeof validBerlinDistricts)[0]>,
);

export const validLocations = {
  [BERLIN]: BERLIN_ALL_DISTRICTS,
  [COLOGNE]: COLOGNE,
  [DUESSELDORF]: DUESSELDORF,
  [FRANKFURT]: FRANKFURT,
  [GERMANY]: GERMANY,
  [ALL_OTHER_CITIES]: ALL_OTHER_CITIES,
  // map all the berlin districts to BERLIN_ALL_DISTRICTS_TAG
  ...validBerlinDistricts.reduce(
    (acc, district) => {
      acc[district.key] = BERLIN_ALL_DISTRICTS;
      return acc;
    },
    {} as Record<string, string>,
  ),
};

export const locationsData = {
  Germany: {
    cities: {
      Cologne: [COLOGNE],
      Duesseldorf: [DUESSELDORF],
      Frankfurt: [FRANKFURT],
      Berlin: validBerlinDistricts.map((district) => district.name),
      All_other_cities: [ALL_OTHER_CITIES],
    },
  },
};

export const mapLocationTags = (location: string) => {
  switch (location) {
    case GERMANY:
      return [GERMANY_TAG];
    case BERLIN:
    case BERLIN_ALL_DISTRICTS:
      return [
        GERMANY_TAG,
        BERLIN_ALL_DISTRICTS_TAG,
        BERLIN_CHARLOTTENBURG_TAG,
        BERLIN_FRIEDRICHSHAIN_TAG,
        BERLIN_HELLERSDORF_TAG,
        BERLIN_KOEPENICK_TAG,
        BERLIN_KREUZBERG_TAG,
        BERLIN_LICHTENBERG_TAG,
        BERLIN_MARZAHN_TAG,
        BERLIN_MITTE_TAG,
        BERLIN_NEUKOELLN_TAG,
        BERLIN_PRENZLAUER_BERG_TAG,
        BERLIN_PANKOW_TAG,
        BERLIN_REINICKENDORF_TAG,
        BERLIN_SCHOENEBERG_TAG,
        BERLIN_SPANDAU_TAG,
        BERLIN_STEGLITZ_TAG,
        BERLIN_TEMPELHOF_TAG,
        BERLIN_TREPTOW_TAG,
        BERLIN_WILMERSDORF_TAG,
        BERLIN_ZEHLENDORF_TAG,
      ];
    case DUESSELDORF:
      return [GERMANY_TAG, DUESSELDORF_TAG];
    case COLOGNE:
      return [GERMANY_TAG, COLOGNE_TAG];
    case FRANKFURT:
      return [GERMANY_TAG, FRANKFURT_TAG];
    case ALL_OTHER_CITIES:
      return [GERMANY_TAG, ALL_OTHER_CITIES_TAG];
    default:
      return [GERMANY_TAG];
  }
};

export const cityLocationsMap = Object.keys(
  locationsData.Germany.cities,
).reduce(
  (acc, city) => {
    acc[city.toLowerCase()] = mapLocationTags(city);
    return acc;
  },
  {} as Record<string, string[]>,
);

export const ageOptions = [
  {
    id: "trying",
    label: "Trying",
    value: FERTILITY_TAG,
    minAge: null,
    maxAge: -1,
  },
  {
    label: "Pregnancy",
    value: EXPECTING_TAG,
    minAge: -1,
    maxAge: 0,
    id: "expecting",
  },
  {
    label: "0-12 months",
    value: INFANT_TAG,
    minAge: 0,
    maxAge: 1,
    id: "infant",
  },
  {
    label: "1-3 years",
    value: TODDLER_TAG,
    minAge: 1,
    maxAge: 3,
    id: "toddler",
  },
  {
    label: "4-6 years",
    value: PRESCHOOLER_TAG,
    minAge: 4,
    maxAge: 6,
    id: "preschooler",
  },
];

export const subscriptionIDsMap = {
  monthly: process.env.NEXT_PUBLIC_MEMBERSHIP_PRICE_MONTHLY,
  yearly: process.env.NEXT_PUBLIC_MEMBERSHIP_PRICE_YEARLY,
};
